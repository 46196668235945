import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  allScreens: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

// manual data to be replace with api
const heroBanner = [
  {
    image: `https://i.ibb.co/gFxZMKc/bbad5188-bba3-4f90-b5ae-9e1076b58359.jpg`,
  },

  {
    image: `https://i.ibb.co/KshF1pd/Whats-App-Image-2024-05-26-at-18-51-28.jpg`,
  },

  {
    image: `https://i.ibb.co/mGx7rsM/hero-slider2.jpg`,
  },
];

const HeroBanner = () => {
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      keyBoardControl={true}
      customTransition="transform 3000ms ease-in-out"
      transitionDuration={10000}
      focusOnSelect={true}
      swipeable={true}
      removeArrowOnDeviceType={["allScreens"]}
    >
      {heroBanner.map(({ image, responsiveImg }, key) => {
        return (
          <>
            <div
              key={key}
              className={`hero-banner d-lg-none`}
              style={{ backgroundImage: `url(${responsiveImg ?? image})` }}
            ></div>
            <div
              key={key}
              className={`hero-banner d-lg-block d-none `}
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          </>
        );
      })}
    </Carousel>

    // <LazyLoadImage width="100%" src={Hero1} alt="hero-images" />
  );
};

export default HeroBanner;
