import "../assets/css/charity.css";
import SectionOne from "../components/Charity/SectionOne";
import CharityHero from "../components/Charity/CharityHero";
import SectionTwo from "../components/Charity/SectionTwo";
import ReachUs from "../components/Charity/ReachUs";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const Charity = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Onebody Charity - Hearts moving hands</title>
        <meta
          name="description"
          content="To help teenagers and young adults unlock a prosperous future
              through education."
        />
      </Helmet>
      <CharityHero />
      <div className="container">
        <div className="charity-section-wrapper">
          <div className="row">
            <div className="col-lg-8">
              <SectionOne />
              <SectionTwo />
            </div>

            <div className="col-lg-4">
              <ReachUs />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Charity;
