import Marquee from "react-easy-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const CharityHero = () => {
  return (
    <section>
      <header>
        <Marquee className="py-lg-5 py-0" duration="10000" align="center">
          <div className="services-marque-header">
            <FontAwesomeIcon className="rotate mx-3" icon={faAsterisk} />{" "}
            Onebody Charity
          </div>

          <div className="services-marque-header">
            <FontAwesomeIcon className="rotate mx-3" icon={faAsterisk} />{" "}
            Onebody Charity
          </div>
        </Marquee>
      </header>

      <div className="charity-hero">
        <div className="charity-hero-overlay">
          {/* <div className=""> */}
            <div className="container hero-content">
              <h1>
                Empowering <span className="pri-color">Teenagers</span> for a
                Brighter Future
              </h1>
              <div className="hero-text">
                Join OneBody Charity as we inspire indigent teenagers across
                SouthWest Nigeria in their educational journey. We believe that
                through education, these kids can escape poverty and grow up to
                become responsible members of society.
              </div>
              <div className="mt-md-3 mt-1">
                <Link target="_blank" to="https://paystack.com/pay/onebodycharity" className="donateBtn">
                  Donate Now
                </Link>
              </div>
            </div>
          {/* </div> */}
        </div>
      </div>
    </section>
  );
};

export default CharityHero;
